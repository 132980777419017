import Vue from 'vue'
import config from '@/config';
import router from '@/config/routes';
import store from '@/config/store';
import 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css'
import DateFilter from './filters/date' // Import date
import Commas from './filters/commas' // Import date

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

Vue.filter('date', DateFilter )
Vue.filter('commas', Commas )

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.prototype.$store = store;
new Vue({
    router,
  render: h => h(config.defaultApp),
}).$mount(config.defaultAppId)
