import Vue from 'vue';
import Router from 'vue-router';
import store from '@/config/store';

Vue.use(Router);

const req = require.context('../modules', true, /config\/routes$/);
const allroutes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/modules/Home')
    },
    {
        path: '/faqs',
        name: 'Faqs',
        component: () => import('@/modules/Faqs')
    },
    {
        path: '/cursos',
        name: 'courses',
        component: () => import('@/modules/Courses')
    },
    {
        path: '/curso/:courseName',
        name: 'Course',
        component: () => import('@/modules/Courses/course')
    },
    {
        path: '/buy/:courseName',
        name: 'Buy',
        component: () => import('@/modules/Courses/Buy'),
    },
    {
        path: '/curso/comprar/:courseName',
        name: 'Course',
        component: () => import('@/modules/Courses/Buy')
    },
    {
        path: '/acerca-de',
        name: 'About',
        component: () => import('@/modules/AboutUs')
    },
    {
        path: '/terminos-y-condiciones',
        name: 'Terms',
        component: () => import('@/modules/Pages/terminos-y-condiciones.vue')
    },
    {
        path: '/politica-privacidad',
        name: 'privacy',
        component: () => import('@/modules/Pages/politica-privacidad.vue')
    },
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('@/modules/Blog')
    },
    {
        path: '/blog/post/:postId',
        name: 'Blog',
        component: () => import('@/modules/Blog/post')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/modules/Login')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/modules/Login/register')
    },
    {
        path: '/logout',
        name: 'Loput',
        beforeEnter: (to, from, next) => {
            store.dispatch('logoutUser')
            next('/login');
          },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/modules/Profile'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile/me',
        name: 'Me',
        component: () => import('@/modules/Profile/me'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile/payments',
        name: 'ProfilePayments',
        component: () => import('@/modules/Profile/payments'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/profile/payments/:invoiceId',
        name: 'ProfilePaymentsDetais',
        component: () => import('@/modules/Profile/paymentDetails'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('@/modules/Admin/'),
        children:[
            {
                path: '/',
                component: () => import('@/modules/Admin/home'),
            },
            {
                path: 'coupons',
                component: () => import('@/modules/Admin/Coupons/Index'),
            },
            {
                path: 'users',
                component: () => import('@/modules/Admin/users'),
            },
            {
                path: 'blog',
                component: () => import('@/modules/Admin/blog/index'),
            },
            {
                path: 'blog/edit/:postId',
                component: () => import('@/modules/Admin/blog/edit'),
            },
            {
                path: 'blog/add/',
                component: () => import('@/modules/Admin/blog/add'),
            },
            {
                path: 'orders',
                component: () => import('@/modules/Admin/orders/Index'),
            },
            {
                path: 'orders/view/:orderId',
                component: () => import('@/modules/Admin/orders/View'),
            },
            {
                path: 'me',
                component: () => import('@/modules/Admin/profile'),
            },
            {
                path: 'courses',
                component: () => import('@/modules/Admin/courses'),
            }
        ],
        meta: {
            requiresAuth: true,
            roles: [1]
        }
    },
    {
        path: '*',
        component: () => import('@/modules/Errors/404'),
        title: '404 página no encontrada',
    },
];

req.keys().forEach((key) => {
    req(key).default.forEach((key2) => {
      allroutes.push(key2);
    });
  });

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: allroutes,
});
router.beforeEach((to, from, next) => {

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if(nearestWithMeta) {
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
      })
      .forEach(tag => document.head.appendChild(tag));
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user.isLogged === true) {
        if(to.meta.roles != undefined) {
            if(!to.meta.roles.includes(parseInt(store.state.user.current_role))) {
                next('/login');
            }

        }

      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});


export default router;
